// This is the template for a single sign
import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { Seo } from "../components/seo";
// ------------> Contact page <------------ //
const ContactPage = (props) => {
  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <div className="text-white text-lg">
        hello [at] radicalnorms [dot] com
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String) {
    strapiSignal(slug: { eq: $slug }) {
      signalContent {
        data {
          signalContent
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default ContactPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Contact Us"}
    description={
      "contact us for specualtive design workshops that explore the impact of extreme climate change futures"
    }
    keywords={"specualtive design, art instalation"}
  />
);
